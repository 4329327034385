table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
}

thead {
    background-color: #000;
    color: #fff;
}

th,
td {
    padding: 10px;
    border: 1px solid #000;
    justify-content: center;
}

tbody tr:nth-child(odd) {
    background-color: #eee;
}