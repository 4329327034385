.map-container {
    height: 500px;
    width: 100%;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;

    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}